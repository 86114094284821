<template>
  <NuxtLink
    :to="`/${product.uri}`"
    class="group text-xs sm:text-sm md:text-base"
    @click="$gtm.trackEvent({ ecommerce: null }); $gtm.trackEvent({
      event: 'select_item',
      ecommerce: {
        items: [item({ product, index, data: gtmData })],
      },
    })"
  >
    <div class="relative bg-primary-100 mb-20 group-hover:bg-primary-200 transition">
      <span
        v-if="product?.productLabel"
        class="absolute block p-10 md:p-15 leading-none top-0 left-0 m-10 md:m-20 bg-white font-serif font-bold z-10"
      >
        {{ product.productLabel }}
      </span>
      <!-- @TODO sales -->
      <!-- <span
        v-if="defaultVariant?.sales?.length"
        class="absolute block p-15 leading-none top-20 left-20 bg-white text-red-500 font-serif font-bold z-10"
      >
        sale
      </span> -->
      <img
        v-if="product?.productImages?.length"
        :src="product.productImages[0].url"
        :srcset="product.productImages[0].srcset"
        sizes="(max-width: 600px) 100px, 100vw"
        :alt="product.title"
        width="475"
        height="475"
        class="mix-blend-darken size-full object-cover"
        :loading="lazy ? 'lazy' : null"
      >
      <img
        v-else
        src="@/assets/img/placeholder.png"
        :alt="product.title"
        width="475"
        height="475"
        class="mix-blend-darken"
        :loading="lazy ? 'lazy' : null"
      >
      <img
        v-if="product.productImages?.length > 1"
        :src="product.productImages[1].url"
        :srcset="product.productImages[1].srcset"
        sizes="(max-width: 600px) 100px, 100vw"
        :alt="product.title"
        width="475"
        height="475"
        class="absolute inset-0 h-full w-full opacity-0 group-hover:opacity-100 transition duration-300"
        :loading="lazy ? 'lazy' : null"
      >
    </div>
    <div class="md:flex md:justify-between md:items-baseline md:space-x-20">
      <h4 class="font-bold group-hover:underline">
        {{ product.title }}
      </h4>
      <p class="whitespace-nowrap text-secondary lowercase">
        {{ defaultVariant?.hasUnlimitedStock && product?.logicTradeRequiredWeeksForDelivery ? $t('product.delivery', { weeks: product?.logicTradeRequiredWeeksForDelivery }) : defaultVariant?.stock || defaultVariant?.hasUnlimitedStock ? $t('product.in-stock') : $t('product.no-stock') }}
      </p>
    </div>
    <p
      v-if="!product.logicTradeConfigurable"
      class="mt-5 mb-10"
    >
      <template v-if="defaultVariant?.sales?.length">
        <span class="text-red-500">
          {{ formatCurrency(defaultVariant.salePrice) }}
        </span>
        <span class="line-through">
          {{ formatCurrency(defaultVariant.price) }}
        </span>
      </template>
      <template v-else>
        {{ formatCurrency(defaultVariant?.price) }}
      </template>
    </p>
    <p
      v-else
      class="mt-5 mb-10"
    >
      <product-price :product="product" />
    </p>
    <!-- @TODO configurations? when colors -->
    <!-- <ul class="flex space-x-5 lg:space-x-10 items-center leading-none text-xs md:text-sm">
      <li>
        <span class="block w-10 h-10 lg:w-20 lg:h-20 rounded-full bg-[#AF6D49]" />
      </li>
      <li>
        <span class="block w-10 h-10 lg:w-20 lg:h-20 rounded-full bg-[#746F47]" />
      </li>
      <li>
        <span class="block w-10 h-10 lg:w-20 lg:h-20 rounded-full bg-[#5A595D]" />
      </li>
      <li>
        <span class="block w-10 h-10 lg:w-20 lg:h-20 rounded-full bg-[#1F1F1D]" />
      </li>
      <li class="translate-y-2">
        + 5 meer
      </li>
    </ul> -->
  </NuxtLink>
</template>

<script setup>
import { computed, formatCurrency, useDataLayer } from '#imports';

// Props
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  gtmData: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
});

// Datalayer
const { item } = useDataLayer();

// Computed properties
const defaultVariant = computed(() => props.product?.variants.find((variant) => variant.isDefault));
</script>
